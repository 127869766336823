import { FormikProps } from "formik";
import { object, string } from "yup";

export type AuthLoginForm = {
	email: string;
	password: string;
};

type UseLoginReturn = {
	year: string;
	loginForm: FormikProps<AuthLoginForm>;
};

export type UseLoginFn = () => UseLoginReturn;

export type AuthLoginViewProps = {
	year: string;
	loginForm: FormikProps<AuthLoginForm>;
};

export const AuthLoginSchema = object().shape({
	email: string().email("Invalid email").required("Required"),
	password: string().min(1, "Too Short!").required("Required"),
});
