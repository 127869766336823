import { useCallback, useEffect, useMemo, useState } from "react";

import { fetchHostConfig } from "@/persistence/http/host-config";
import { AppConfig } from "@/services/config";
import { HostConfigResponse } from "@/types/host-config";

import { HostConfigState } from "./host-config.types";

const CONFIG_ENDPOINT = AppConfig.HOST_CONFIG_ENDPOINT || "https://dev-config.powerledger.io";

/**
 * Fetches and configures a Host's configuration in accordance to the host's URL given
 * @param hostURL Optional overwrite of current host name form URL
 * @returns Host Config Provider Context Value
 */
export const useHostConfig = (hostURL?: string): HostConfigState => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [hostConfig, setHostConfig] = useState<HostConfigResponse | null>(null);

	useEffect(() => {
		const abortController = new AbortController();

		const getHostConfig = async () => {
			setIsLoading(true);
			setError(null);
			setHostConfig(null);

			try {
				const hostConfig = await fetchHostConfig(hostURL, abortController);

				setHostConfig(hostConfig);
			} catch (error) {
				setError((error as Error).message);
				setHostConfig(null);
			} finally {
				setIsLoading(false);
			}
		};

		getHostConfig();

		return () => abortController.abort();
	}, [hostURL]);

	const hostHasFlag = useCallback(
		(flag: "isVB") => {
			if (!hostConfig) {
				throw new Error("Request against Host Config prior to Load");
			}

			return (hostConfig.customFlags || []).includes(flag);
		},
		[hostConfig],
	);

	return useMemo(
		() => ({
			isLoading,
			error,
			tradingGroup: hostConfig
				? {
						id: hostConfig.id,
						ref: hostConfig.ref,
						name: hostConfig.name,
						status: hostConfig.status,
						domain: hostConfig.domain,
						timeZone: hostConfig.timeZone,
						logo: hostConfig.logo ? `${CONFIG_ENDPOINT}/${hostConfig.logo}` : undefined,
						logoInverse: hostConfig.logoInverse ? `${CONFIG_ENDPOINT}/${hostConfig.logoInverse}` : undefined,
				  }
				: undefined,
			config: hostConfig
				? {
						customFlags: hostConfig.customFlags,
						apiEndpoint: hostConfig.apiEndpoint,
						auth: {
							...hostConfig.auth,
							providers: hostConfig.auth.providers
								? hostConfig.auth.providers.map((provider) => ({
										...provider,
										icon: provider.icon ? `${CONFIG_ENDPOINT}/${provider.icon}` : undefined,
								  }))
								: undefined,
						},
				  }
				: undefined,
			theme: hostConfig ? hostConfig.theme : undefined,
			hostHasFlag,
		}),
		[isLoading, error, hostConfig, hostHasFlag],
	);
};
