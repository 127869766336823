import { createContext, useContext } from "react";

import { AppConfig, AppConfigType } from "@/services/config";

export const AppConfigContext = createContext<AppConfigType>(AppConfig);

type LoadedAppConfig = Omit<AppConfigType, "tradingGroup" | "config"> &
	Required<Pick<AppConfigType, "tradingGroup" | "config">>;

/**
 * Returns the current app configuration stored in its related context.
 * @param throwNotLoaded When true, the function will throw if the trading group config has not been loaded.
 */
function useAppConfig(throwNotLoaded?: false): AppConfigType;

function useAppConfig(throwNotLoaded?: true): LoadedAppConfig;

function useAppConfig(throwNotLoaded?: boolean): AppConfigType | LoadedAppConfig;

function useAppConfig(throwNotLoaded = false) {
	const contextValue = useContext(AppConfigContext);

	if (!contextValue) {
		throw new Error("Unable to find Trading Group Config context");
	}

	if (throwNotLoaded === true && (!contextValue.tradingGroup || !contextValue.config)) {
		throw new Error("Trading Group not loaded when expected");
	}

	return contextValue;
}

export { useAppConfig };
