import { Helmet } from "react-helmet";
import { RouterProvider } from "react-router-dom";

import { AppProvider } from "@/providers/app-provider";
import { router } from "@/router";

import { GlobalErrorBoundary } from "./components/error-components/global-boundary";

function App() {
	return (
		<GlobalErrorBoundary>
			<AppProvider>
				<Helmet>
					<meta name="theme-color" content="var(--theme-ui-colors-primary)" media="(prefers-color-scheme: light)" />
					<meta name="theme-color" content="var(--theme-ui-colors-background)" media="(prefers-color-scheme: dark)" />
				</Helmet>
				<RouterProvider router={router} />
			</AppProvider>
		</GlobalErrorBoundary>
	);
}

export default App;
