import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { AppConfig } from "@/services/config";

if (import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release) {
	Sentry.init({
		dsn: AppConfig?.SENTRY_DSN,
		integrations: [new BrowserTracing()],
		release: AppConfig?.VERSION,
		environment: AppConfig?.ENV,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}
