import { useAuthService } from "@powerledger/ui-auth-service";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { isAuthenticated } = useAuthService();

	if (!isAuthenticated()) {
		return <Navigate to="/auth/login" />;
	}

	return children ? <>{children}</> : <Outlet />;
};
