import { createBrowserRouter } from "react-router-dom";

import { AppEnclosure } from "@/components/app-enclosure";
import { Error404 } from "@/components/error-components/404";
import { ProtectedRoute } from "@/components/protected-route/protected-route";
import { AuthLogin, LoginCallback } from "@/pages/auth";
import { HomePage } from "@/pages/home-page";

export const router = createBrowserRouter([
	{
		path: "/",

		element: <ProtectedRoute />,
		children: [
			{
				path: "/",
				element: <AppEnclosure />,
				children: [
					{
						path: "/",
						element: <HomePage />,
					},
					{
						path: "*",
						element: <Error404 />,
					},
				],
			},
		],
	},
	{
		path: "/auth",
		children: [
			{
				path: "login/callback",
				element: <LoginCallback />,
			},
			{
				path: "login",
				element: <AuthLogin />,
			},
			{
				path: "*",
				element: <Error404 />,
			},
		],
	},
	{
		path: "*",
		element: <Error404 />,
	},
]);
