import { Container, Flex, Heading, Text } from "@powerledger/ui-component-lib-v2";
import { useTranslation } from "react-i18next";

import { IconHelp } from "../../../icons";

export const Error404: React.FC = () => {
	const { t } = useTranslation(["common"]);
	return (
		<Flex sx={{ height: "100vh", width: "100%", alignItems: "center", justifyContent: "center" }}>
			<Container
				sx={{
					width: "100%",
					maxWidth: 480,
				}}
				py={16}
			>
				<Flex sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
					<IconHelp height={96} width={96} />

					<Heading sx={{ pt: 6 }}>{t("common:errors.404.title")}</Heading>
					<Text sx={{ maxWidth: 220, textAlign: "center", pt: 2 }}>{t("common:errors.404.sub")}</Text>
				</Flex>
			</Container>
		</Flex>
	);
};
