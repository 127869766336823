// INFO: We fallback to raw CSS and HTML elements here as the boundary is defined prior
// to UI Lib. It is important to remember that anything could trigger this, from failure
// to load chunks, initiate styling to issues in the React Runtime.

// WARN: But seriously, no additional packages other then React & Sentry in this element!!!

import "./global-error.css";

import { captureException, withScope } from "@sentry/react";
import React, { ErrorInfo } from "react";

import { ReactComponent as ErrorImage } from "./global-error.svg";

type GlobalErrorBoundaryProps = {
	children?: React.ReactNode;
};

type GlobalErrorBoundaryState = {
	hasError: boolean;
};

/**
 * Performs a reload of the Application, needs to be called separately
 * as it is bound to the Location Instance
 */
const reloadPage = () => window.location.reload();

/**
 * Provides a catch all root error boundary to inform users to attempt to reload the
 * application if something expectant occurs
 *
 * Currently React only supports this functionality within a Class Component with
 * possible hooks coming in the future...
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 */
export class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
	constructor(props: GlobalErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ hasError: true });
		withScope((scope) => {
			errorInfo && scope.setExtras({ errorInfo });
			captureException(error);
		});
	}

	render() {
		if (this.state.hasError) {
			// Skipping the use of a component library in case that is the cause of the error
			return (
				<div className="errorContainer">
					<div className="errorFloat">
						<ErrorImage />
						<h1>This is Awkward</h1>
						<p>Unfortunately an unexpected issue occurred, please reload the page and try again.</p>
						<button onClick={reloadPage}>Reload Page</button>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}
