import "virtual:vite-plugin-sentry/sentry-config";

import { AppConfigState } from "@/providers/app-config/app-config.types";

interface RuntimeConfigType {
	ENV?: string;

	/**
	 * Sentry
	 */
	SENTRY_DSN?: string;

	/**
	 * Host File Config
	 */
	HOSTNAME_OVERRIDE?: string;
	HOST_CONFIG_ENDPOINT?: string;

	/**
	 * Locize Config
	 */
	LOCIZE_APIKEY?: string;
	LOCIZE_PROJECTID?: string;
	LOCIZE_REFLNG?: string;
	LOCIZE_VERSION?: string;

	/**
	 * Default Auth Service Config
	 */
	AUTH_ISSUER_ID?: string;
	AUTH_CLIENT_ID?: string;
	AUTH_BASE_URL?: string;
}

declare global {
	interface Window {
		_RT_CONFIG_?: RuntimeConfigType;
	}
}

export type AppConfigType = {
	VERSION: string;
} & Partial<RuntimeConfigType> &
	Partial<AppConfigState>;

const RuntimeConfig = window._RT_CONFIG_ || {};

export const AppConfig: AppConfigType = {
	...RuntimeConfig,
	VERSION: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release,
};
