import { OktaAuthAdaptor } from "@powerledger/ui-auth-service";

/**
 * Sadly Transactive strays away from the pack...
 * These function override the Okta Auth Adaptor
 * functionality to support these requirements
 */
export const XGridOktaAuthAdaptor: typeof OktaAuthAdaptor = {
	...OktaAuthAdaptor,

	/**
	 * Override of Sign-In
	 * @reason User accounts are wrapped in fake email addresses... This intercepts the requests
	 * and wraps the given username into an email
	 */
	signIn: (client, { username, password }) => {
		return OktaAuthAdaptor.signIn(client, {
			username: username.includes("@") ? username : generateOktaUsername(username),
			password,
		});
	},
};

const generateOktaUsername = (username: string) => {
	const strippedUsername = username.replace(/\s/g, "");
	const urlPrefix = window.location.hostname.toLowerCase().split("-")[0];
	const loginPrefix =
		{
			localhost: "dev_",
			dev: "dev_",
			staging: "staging_",
		}[urlPrefix] || "";

	return `${loginPrefix}${strippedUsername}@xgrid.com`;
};
