import { useAuthService } from "@powerledger/ui-auth-service";
import { SideBarOption, useColorMode } from "@powerledger/ui-component-lib-v2";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
	IconGroup,
	IconLock,
	IconLogout,
	IconModeDark,
	IconModeLight,
	IconOverview,
	IconPolicy,
	IconPower,
	IconSettings,
	IconTransaction,
	IconUser,
} from "@/icons";

export const useSidebar = () => {
	const { t } = useTranslation(["common"]);
	const { signOut } = useAuthService();

	const nav = useNavigate();

	const [mode, setMode] = useColorMode();

	const year = useMemo(() => `${new Date().getFullYear()}`, []);
	const legalText = t("common:legal_footer", { year });

	const navOverview = useCallback(() => nav("/"), [nav]);
	const navTradingGroups = useCallback(() => nav("/trading-groups"), [nav]);
	const navMeters = useCallback(() => nav("/meters"), [nav]);
	const navTransactions = useCallback(() => nav("/transactions"), [nav]);
	const navUsers = useCallback(() => nav("/users"), [nav]);
	const toggleAppearance = useCallback(() => {
		setMode(mode === "dark" ? "light" : "dark");
	}, [mode, setMode]);
	const navPrivacy = useCallback(() => nav("/privacy"), [nav]);
	const navTerms = useCallback(() => nav("/terms"), [nav]);
	const navSettings = useCallback(() => nav("/settings"), [nav]);

	const [links] = useState<SideBarOption[]>([
		{ id: "overview", name: t("common:nav.overview"), onClick: navOverview, icon: IconOverview },
		{ id: "trading-groups", name: t("common:nav.tg"), onClick: navTradingGroups, icon: IconGroup },
		{ id: "meters", name: t("common:nav.meters"), onClick: navMeters, icon: IconPower },
		{
			id: "transactions",
			name: t("common:nav.transactions"),
			onClick: navTransactions,
			icon: IconTransaction,
		},
		{ id: "users", name: t("common:nav.users"), onClick: navUsers, icon: IconUser },
	]);

	const subLinks = useMemo(
		() => [
			{
				id: "appearance",
				name: t("common:toggle_appearance"),
				onClick: toggleAppearance,
				icon: mode === "light" ? IconModeDark : IconModeLight,
			},
			{ id: "privacy", name: t("common:privacy"), onClick: navPrivacy, icon: IconLock },
			{ id: "tos", name: t("common:tos"), onClick: navTerms, icon: IconPolicy },
			{ id: "settings", name: t("common:settings"), onClick: navSettings, icon: IconSettings },
			{ id: "sign-out", name: t("common:sign_out"), onClick: signOut, icon: IconLogout },
		],
		[mode],
	);
	return { legalText, links, subLinks };
};
