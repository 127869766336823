import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// This will usually be used for promises, we don't want to cache responses
			cacheTime: 0,
			// We don't need to retry promises
			// This also helps with an issue when using RenderHook
			retry: false,
		},
	},
});

export const ReactQueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
