import { AuthServiceProvider } from "@powerledger/ui-auth-service";
import React, { useMemo } from "react";

import { useAppConfig } from "../app-config";
import { useHostConfig } from "../host-config";
import { AppAuthServiceLoading } from "./app-auth-service-loading";
import { XGridOktaAuthAdaptor } from "./okta-adaptor-overrides";

export const AppAuthServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children, ...props }) => {
	const { config } = useHostConfig();
	const { AUTH_ISSUER_ID, AUTH_CLIENT_ID, AUTH_BASE_URL } = useAppConfig();

	const auth = config?.auth;

	const authConfig = useMemo(() => {
		const defaultAuthServiceConfig =
			AUTH_ISSUER_ID && AUTH_CLIENT_ID && AUTH_BASE_URL
				? {
						issuerId: AUTH_ISSUER_ID,
						clientId: AUTH_CLIENT_ID,
						baseURL: AUTH_BASE_URL,
						pkce: true,
				  }
				: null;

		const hostAuthServiceConfig = auth
			? {
					issuerId: auth.issuerID,
					clientId: auth.clientID,
					baseURL: auth.baseURL,
					pkce: true,
			  }
			: null;

		return hostAuthServiceConfig || defaultAuthServiceConfig;
	}, [auth]);

	return (
		<AuthServiceProvider adaptor={XGridOktaAuthAdaptor} config={authConfig} {...props}>
			<AppAuthServiceLoading>{children}</AppAuthServiceLoading>
		</AuthServiceProvider>
	);
};
