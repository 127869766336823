import i18n, { i18n as I18n } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { IS_PRODUCTION } from "@/configs/env";

import { getI18nLocizeInitOptions, injectI18nLocizePlugins } from "./locize";

const FALLBACK_LANG = "en";
const STORAGE_LOOKUP_KEY = "lang";

const i18nLocizeInitOptions = getI18nLocizeInitOptions();

const injectI18nBasePlugins = (i18nBase: I18n) => {
	i18nBase
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(LanguageDetector)
		// pass the i18n instance to react-i18next.
		.use(initReactI18next);
};

injectI18nBasePlugins(i18n);
injectI18nLocizePlugins(i18n);

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
i18n.init({
	debug: !IS_PRODUCTION,
	fallbackLng: FALLBACK_LANG,
	ns: ["common", "auth"],
	defaultNS: "common",
	interpolation: {
		escapeValue: false,
	},
	detection: {
		lookupLocalStorage: STORAGE_LOOKUP_KEY,
		lookupSessionStorage: STORAGE_LOOKUP_KEY,
		caches: IS_PRODUCTION ? ["sessionStorage"] : [],
	},
	...i18nLocizeInitOptions,
});

export { i18n };
