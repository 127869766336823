import React from "react";

import { PageHeader } from "@/components/page-header";

// This is a temporary placeholder page, should remove later
export const HomePage: React.FC = () => {
	return (
		<>
			<PageHeader title="Home Page" />
		</>
	);
};
