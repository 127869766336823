import { Box, Button, Container, Flex, Input, Label, Spinner, Text } from "@powerledger/ui-component-lib-v2";
import React from "react";
import { useTranslation } from "react-i18next";

import { PLLogo } from "@/icons";

import type { AuthLoginViewProps } from "./login-types";

export const AuthLoginView: React.FC<AuthLoginViewProps> = ({ year, loginForm }) => {
	const { t } = useTranslation(["common", "auth"]);
	const error = loginForm.status;

	return (
		<Flex sx={{ height: "100vh", width: "100%", alignItems: "center", justifyContent: "center" }}>
			<Container
				sx={{
					width: "100%",
					maxWidth: 480,
					px: 8,
					py: 8,
				}}
			>
				<Flex sx={{ flexDirection: "column", alignItems: "center" }}>
					<Flex sx={{ justifyContent: "center", alignItems: "center", height: 80, width: 280 }}>
						<PLLogo height="90%" width="90%" />
					</Flex>
				</Flex>
				<Flex sx={{ justifyContent: "center" }}>
					<Text color="red">{error}</Text>
				</Flex>
				<form onSubmit={loginForm.handleSubmit}>
					<Flex sx={{ flexDirection: "column" }} py={3}>
						<Label htmlFor="email">{t("auth:login.email")}</Label>
						<Input id="email" mb={3} type="email" onChange={loginForm.handleChange} value={loginForm.values.email} />
						<Label htmlFor="password">{t("auth:login.password")}</Label>
						<Input
							id="password"
							mb={4}
							type="password"
							onChange={loginForm.handleChange}
							value={loginForm.values.password}
						/>
					</Flex>
					<Flex sx={{ flexDirection: "column", alignItems: "center" }}>
						<Button
							type="submit"
							variant="success"
							disabled={!loginForm.isValid || loginForm.isSubmitting}
							onClick={loginForm.submitForm}
						>
							{loginForm.isSubmitting ? <Spinner size={32} color="#fff" pt="1" /> : t("auth:login.submit")}
						</Button>
					</Flex>
				</form>
			</Container>
			<Box sx={{ position: "fixed", bottom: 1 }}>
				<Text>{t("common:legal_footer", { year })}</Text>
			</Box>
		</Flex>
	);
};
