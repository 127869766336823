import { Spinner } from "@powerledger/ui-component-lib-v2";
import React from "react";
import { Navigate } from "react-router-dom";

import { useLoginCallback } from "./use-login-callback";

export const LoginCallback: React.FC = () => {
	const { error, isAuthed } = useLoginCallback();

	if (error) {
		return <Navigate to="/login" state={{ error }} />;
	}

	if (isAuthed) {
		return <Navigate to="/" />;
	}

	return <Spinner />;
};
