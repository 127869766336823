import { i18n as I18n, InitOptions } from "i18next";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import LastUsed from "locize-lastused";

import { IS_PRODUCTION } from "@/configs/env";
import { AppConfig, AppConfigType } from "@/services/config";

export type LocizeOptions = InitOptions["locizeLastUsed"] & { allowedAddOrUpdateHosts: string[] };

export const getLocizeOptions = (appConfig: AppConfigType): Partial<LocizeOptions> => {
	return {
		projectId: appConfig.LOCIZE_PROJECTID,
		apiKey: appConfig.LOCIZE_APIKEY || "", // You MUST NOT expose your app's API key to production!!!
		referenceLng: appConfig.LOCIZE_REFLNG,
		version: appConfig.LOCIZE_VERSION,
		allowedAddOrUpdateHosts: [
			"localhost",
			"localhost:4200",
			"staging-projectpear.powerledger.io",
			"staging-vb.powerledger.io",
			"staging-energie.powerledger.io",
			"staging-evermore.powerledger.io",
			"staging-apn.powerledger.io",
			"staging-tpddl.powerledger.io",
			"staging-vpp-demo.powerledger.io",
			"staging-slabtarrifs.powerledger.io",
			"staging-ekwateur.powerledger.io",
			"dev-vb.powerledger.io",
			"dev-evermore.powerledger.io",
			"dev-tpddl.powerledger.io",
			"dev-vpp-demo.powerledger.io",
			"dev-renewnexus.powerledger.io",
			"dev-apn.powerledger.io",
			"dev-dehavilland.powerledger.io",
			"dev-geny.powerledger.io",
			"dev-readings-secure.powerledger.io",
			"dev-shac.powerledger.io",
			"dev-energie.powerledger.io",
			"dev-energie-test.powerledger.io",
			"dev-ekwateur.powerledger.io",
			"dev-smartcommunity.powerledger.io",
			"energie-test.powerledger.io",
		],
	};
};

export const getI18nLocizeInitOptions = (): InitOptions => {
	const locizeOptions = getLocizeOptions(AppConfig);

	if (!locizeOptions.projectId) {
		return {};
	}

	return {
		saveMissing: !IS_PRODUCTION,
		backend: locizeOptions,
		locizeLastUsed: locizeOptions as LocizeOptions,
	};
};

export const injectI18nLocizePlugins = (i18nBase: I18n) => {
	const locizeOptions = getLocizeOptions(AppConfig);

	if (!locizeOptions.projectId) {
		return i18nBase;
	}

	i18nBase
		// i18next-locize-backend
		// loads translations from your project, saves new keys to it (saveMissing: true)
		// https://github.com/locize/i18next-locize-backend
		.use(Backend)
		// locize-editor
		// InContext Editor of locize
		.use(locizePlugin);

	if (!IS_PRODUCTION) {
		// locize-lastused
		// sets a timestamp of last access on every translation segment on locize
		// -> safely remove the ones not being touched for weeks/months
		// https://github.com/locize/locize-lastused
		i18nBase.use(LastUsed);
	}

	return i18nBase;
};
