import { AppConfig } from "@/services/config";
import { stringToSHA1 } from "@/utils/encrypt";
import { getHostNameFromURL, joinURL } from "@/utils/url";

const getDomainHash = (hostURL?: string) => {
	// Global access required to access current location of browser
	const host = getHostNameFromURL(hostURL || AppConfig.HOSTNAME_OVERRIDE || location.origin);

	return stringToSHA1(host);
};

const CONFIG_ENDPOINT = AppConfig.HOST_CONFIG_ENDPOINT || "https://dev-config.powerledger.io";

export const fetchHostConfig = async (hostURL?: string, abortController?: AbortController) => {
	const domainHash = getDomainHash(hostURL);
	const url = joinURL(CONFIG_ENDPOINT, domainHash, "config.json");

	const response = await fetch(url, { signal: abortController?.signal });

	if (!response.ok) {
		if (response.status === 404) throw new Error("Host config not found");

		throw new Error("Could not fetch host config");
	}

	const hostConfigResponse = await response.json();

	return hostConfigResponse;
};
