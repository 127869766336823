import { AppThemeProvider } from "@powerledger/ui-component-lib-v2";
import { Suspense, useMemo } from "react";

import { PageLoader } from "@/components/page-loader";
import { AppConfigContext } from "@/providers/app-config";
import { useHostConfig } from "@/providers/host-config";
import { AppConfig } from "@/services/config";

import { AppAuthServiceProvider } from "../app-auth-service";
import { ReactQueryProvider } from "../react-query";

interface AppProviderProps {
	hostURL?: string;
	children?: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ hostURL, children }) => {
	const hostConfig = useHostConfig(hostURL);
	const appConfigContextValue = useMemo(() => ({ ...AppConfig, ...hostConfig }), [hostConfig]);

	return (
		<AppThemeProvider>
			<Suspense fallback={<PageLoader />}>
				<ReactQueryProvider>
					<AppConfigContext.Provider value={appConfigContextValue}>
						<AppAuthServiceProvider>{children}</AppAuthServiceProvider>
					</AppConfigContext.Provider>
				</ReactQueryProvider>
			</Suspense>
		</AppThemeProvider>
	);
};
