import { AppEnclosure as AppEnclosureComponent } from "@powerledger/ui-component-lib-v2";
import { Outlet } from "react-router-dom";

import { SidebarLogo } from "@/icons";

import { SideBar } from "../sidebar/sidebar";

export const AppEnclosure = () => {
	return <AppEnclosureComponent logo={SidebarLogo} content={<Outlet />} nav={<SideBar />} />;
};
