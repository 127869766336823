import React from "react";
import { Button, Flex, Heading } from "theme-ui";

type PageHeaderProps = {
	title: string;
	subTitle?: string;
	action?: { title: string; onClick: () => void };
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, subTitle, action }) => (
	<Flex sx={{ justifyContent: "center", borderBottom: "1px solid", borderBottomColor: "form-select-bg" }}>
		<Flex
			sx={{
				flex: 1,
				maxWidth: 1200,
				height: 96,
				px: 2,
				alignItems: "center",
				flexDirection: "row",
			}}
		>
			<Flex sx={{ flex: 1, flexDirection: "column" }}>
				<Heading as="h1" sx={{ pb: 1 }}>
					{title}
				</Heading>
				{subTitle && (
					<Heading as="h4" sx={{ opacity: 0.6 }}>
						{subTitle}
					</Heading>
				)}
			</Flex>

			<Flex>{action && <Button onClick={action.onClick}>{action.title}</Button>}</Flex>
		</Flex>
	</Flex>
);
