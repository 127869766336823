import { SignInResponse, useAuthService } from "@powerledger/ui-auth-service";
import { FormikConfig, useFormik } from "formik";
import type { TFunction } from "i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { AuthLoginForm, AuthLoginSchema, UseLoginFn } from "./login-types";

export const useLogin: UseLoginFn = () => {
	const { t } = useTranslation(["common", "auth"]);
	const nav = useNavigate();
	const { signIn, isAuthenticated } = useAuthService();
	const year = useMemo(() => `${new Date().getFullYear()}`, []);

	const isAuth = isAuthenticated();
	useEffect(() => {
		if (isAuth) {
			nav("/");
		}
	}, [isAuth]);

	const onSubmit = useCallback<FormikConfig<AuthLoginForm>["onSubmit"]>(
		({ email, password }, { setSubmitting, setStatus }) =>
			signIn({
				username: email,
				password,
			}).then(handleLogInResponse({ nav, t, setSubmitting, setStatus })),

		[],
	);

	const loginForm = useFormik<AuthLoginForm>({
		initialValues: {
			email: "",
			password: "",
		},
		validateOnMount: true,
		validationSchema: AuthLoginSchema,
		onSubmit,
	});

	return {
		year,
		loginForm,
		t,
	};
};

const handleLogInResponse =
	({
		nav,
		t,
		setSubmitting,
		setStatus,
	}: {
		nav: NavigateFunction;
		t: TFunction<["common", "auth"]>;
		setSubmitting: (isSubmitting: boolean) => void;
		setStatus: (status?: string | null) => void;
	}) =>
	(resp: SignInResponse) => {
		switch (resp) {
			case SignInResponse.SUCCESS:
				console.log("NAV TO HOME");

				return nav("/");

			case SignInResponse.REQ_MFA:
				return nav("/auth/mfa");

			case SignInResponse.REQ_MFA_ENROLL:
				return nav("/auth/mfa/enrol");

			case SignInResponse.PW_EXPIRED:
				setStatus(t("auth:errors.PW_EXPIRED"));
				break;

			case SignInResponse.LOCKED_OUT:
				setStatus(t("auth:errors.ACCOUNT_LOCKOUT"));
				break;

			default:
				setStatus(t("common:errors.UNKNOWN"));
				break;
		}
		setSubmitting(false);
	};
