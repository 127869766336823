import { useAuthService } from "@powerledger/ui-auth-service";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

export const useLoginCallback = () => {
	const { useAuthCallbackHandler, getTokens } = useAuthService();
	const { loginCallback } = useAuthCallbackHandler();
	const [searchParams] = useSearchParams();

	const queryCode = searchParams.get("code");
	const queryState = searchParams.get("state");
	const queryError = searchParams.get("error");

	const error = queryError || !queryCode || !queryState ? queryError || "Missing Auth Values" : false;

	const {
		error: requestError,
		isLoading,
		data,
	} = useQuery({
		enabled: !error,
		queryFn: () => loginCallback({ code: queryCode, state: queryState }).then(getTokens),
	});

	const isAuthed = !isLoading && Boolean(data);

	return {
		error: error || requestError,
		isAuthed,
	};
};
